import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useIntl, FormattedMessage } from 'react-intl'
import { ArticleJsonLd, SpeakableJsonLd } from 'gatsby-plugin-next-seo'

import useDateFns from '@hooks/useDateFns'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { GlossaryScopeProvider } from '@providers/glossaryScopeProvider'
import ContentfulComponentArray from '@tools/ContentfulComponentArray'
import Container from '@objects/container'
import Stage from '@components/stage'
import Copy from '@objects/copy'
import Button from '@objects/button'
import Headline from '@objects/headline'
import Sources from '@objects/sources'
import TopicTeaserGrid from '@components/topicTeaserGrid'
import SharingBar from '@components/sharingBar'
import NewsletterTeaser from '@components/newsletterTeaser'
import Separator from '@objects/separator'

const useStyles = makeStyles((theme) => ({
  article: {
    marginBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(20),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(30),
    },
  },
  downloadContainer: {
    marginTop: theme.spacing(12),
    textAlign: 'right',
  },
  downloadHeadline: {
    margin: `${0}!important`,
    ...theme.typography.label,
  },
  downloadItem: {
    display: 'block',
    marginTop: theme.spacing(4),
  },
  shareBarTopContainer: {
    position: 'relative',
  },
  shareBarTop: {
    textAlign: 'left',
    width: 'fit-content',
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      position: 'absolute',
      top: theme.spacing(2),
      left: theme.spacing(-24),
    },
    [theme.breakpoints.up('xl')]: {
      left: theme.spacing(-36),
    },
  },
  shareBarBottomContainer: {},
  shareBarBottom: {
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(-7),
    },
  },
  shareBarBottomCopy: {
    textAlign: 'center',
    marginBottom: theme.spacing(8),
  },
}))

function ArticlePage({ data, pageContext, pathContext }) {
  const intl = useIntl()
  const theme = useTheme()
  const classes = useStyles()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const { formatDate } = useDateFns()

  const createdAt =
    data.contentfulArticle?.date ?? data.contentfulArticle?.createdAt
  const stageImage = data.contentfulArticle?.stageImageWithFocalpoint
    ? data.contentfulArticle?.stageImageWithFocalpoint.image
    : data.contentfulArticle?.stageImage
  const stageImageMobile = data.contentfulArticle?.stageImageMobile
  const stageImageFocalPoint =
    data.contentfulArticle?.stageImageWithFocalpoint?.focalPoint
  const articletitle = data.contentfulArticle?.title
  const pdf = data.contentfulArticle?.pdf
  const downloadImages = data.contentfulArticle?.downloadImages
  const author = data.contentfulArticle?.author
  const authorDescription = data.contentfulArticle?.authorDescription
  const authorImage = data.contentfulArticle?.authorImage
  const articlecontent = data.contentfulArticle?.copy?.json?.content
  const articleintro =
    data.contentfulArticle?.introCopy?.childMarkdownRemark?.html
  const articlesources = data.contentfulArticle?.sources
  const relatedArticles = data.contentfulArticle?.relatedArticles
  const articleQuote = data.contentfulArticle?.ogDescription

  const relatedArticlesMapped = relatedArticles
    ?.filter((filterArticle) => filterArticle.title && filterArticle.slug)
    ?.map((article) => {
      const cat = !!article.topic___category ? article.topic___category : null
      const path =
        cat === null
          ? intl.formatMessage({ id: 'press.article.path' })
          : cat[0].topic___category___list?.[0].identifier === 'magazin'
          ? intl.formatMessage({ id: 'magazine.path' })
          : `${intl.formatMessage({ id: 'inform.path' })}${cat[0].slug}/`

      return {
        headline: article?.title,
        copy: article.teaserCopy?.childMarkdownRemark?.html,
        image: article.teaserImageWithFocalpoint
          ? article.teaserImageWithFocalpoint.image.fluid
          : article.teaserImage?.fluid,
        imageAlt: article.teaserImageWithFocalpoint
          ? article.teaserImageWithFocalpoint.image.description
          : article.teaserImage?.description,
        focalPoint: article.teaserImageWithFocalpoint?.focalPoint,
        slug: article.slug ? `${path}${article.slug}/` : article.url,
        url: !!article.url && article.url,
      }
    })

  return (
    <React.Fragment>
      <ArticleJsonLd
        url={pathContext.canonical}
        headline={articletitle}
        datePublished={createdAt}
        overrides={{
          '@type': 'NewsArticle',
        }}
      />
      <SpeakableJsonLd cssSelector={['article']} />
      <Stage
        image={stageImage}
        imageMobile={stageImageMobile}
        focalPoint={stageImageFocalPoint}
        title={articletitle}
        ariaLabel={articletitle}
        subtitle={intl.formatMessage({ id: 'article.press.subline' })}
        author={
          author
            ? {
                name: author,
                description: authorDescription,
                avatar: authorImage?.file?.url,
              }
            : null
        }
        date={formatDate(createdAt, 'dd.MM.yyyy')}
      />
      <div>
        <Container width="sm" className={classes.shareBarTopContainer}
        ariaLabel="arialabel.article"
        >
          <SharingBar
            type={isLarge ? 'vertical' : 'horizontal'}
            className={classes.shareBarTop}
            pageContext={pageContext}
            fbQuote={articleQuote}
          />
        </Container>
        <article className={classes.article}>
          {articleintro && (
            <Container width="sm">
              <Copy type="intro">{articleintro}</Copy>
            </Container>
          )}
          {articlecontent && (
            <GlossaryScopeProvider>
              <ContentfulComponentArray componentarray={articlecontent} />
            </GlossaryScopeProvider>
          )}
          {(!!pdf || !!downloadImages?.length > 0) && (
            <Container
              className={classes.downloadContainer}
              type="article"
              width="sm"
            >
              <Headline className={classes.downloadHeadline} level={3}>
                <FormattedMessage id="article.downloads" />
              </Headline>
              {!!pdf && (
                <Button
                  className={classes.downloadItem}
                  type="text"
                  icon="Download"
                  href={pdf.file.url}
                >
                  {pdf.title} (PDF,{' '}
                  {(pdf.file?.details.size / (1024 * 1024)).toFixed(2)}MB)
                </Button>
              )}
              {!!downloadImages &&
                downloadImages.map((image, i) => (
                  <Button
                    key={image.title + i}
                    className={classes.downloadItem}
                    type="text"
                    icon="Download"
                    href={image.file.url}
                  >
                    {image.title} (
                    {image.file.contentType.includes('png') ? 'PNG' : 'JPG'},{' '}
                    {(image.file?.details.size / (1024 * 1024)).toFixed(2)}MB)
                  </Button>
                ))}
            </Container>
          )}
          {articlesources && (
            <Container type="article" width="sm">
              <Headline>
                {intl.formatMessage({ id: 'article.sources' })}
              </Headline>
              <Sources items={articlesources} />
            </Container>
          )}
        </article>
        <Copy className={classes.shareBarBottomCopy}>
          {intl.formatMessage({ id: 'sharing.copy' })}
        </Copy>
        <Container width="sm" className={classes.shareBarBottomContainer}>
          <SharingBar
            className={classes.shareBarBottom}
            pageContext={pageContext}
            fbQuote={articleQuote}
          />
          <Separator />
        </Container>

        {relatedArticles && (
          <Container padded background={theme.palette.background.grey}
          ariaLabel="arialabel.relatedarticles"
          >
            <TopicTeaserGrid
              headline={intl.formatMessage({
                id: 'article.relatedArticles',
              })}
              headlineLevel={3}
              topics={relatedArticlesMapped}
              gridMap={
                relatedArticles.length === 2
                  ? [6, 6, 6, 6, 6, 6, 6]
                  : [4, 4, 4, 4, 4, 4, 4]
              }
              shadow={false}
            />
          </Container>
        )}

        <NewsletterTeaser
          title={intl.formatMessage({ id: 'newsletter.hl' })}
          copy={intl.formatMessage({ id: 'newsletter.copy' })}
        />
      </div>
    </React.Fragment>
  )
}

ArticlePage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticleBySlug(
    $slug: String!
    $locale: String!
    $contentful_id: String!
  ) {
    SlugByContentfulId: contentfulArticle(
      contentful_id: { eq: $contentful_id }
      node_locale: { ne: $locale }
    ) {
      slug
      node_locale
      fields {
        fullPath
      }
      topic___category {
        slug
      }
    }
    contentfulArticle(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      contentful_id
      createdAt
      date
      naviTitle
      title
      author
      authorDescription
      authorImage {
        file {
          url
        }
      }
      introCopy {
        childMarkdownRemark {
          html
        }
      }
      copy {
        json
      }
      sources {
        description
        label
        url
      }
      pdf {
        title
        file {
          url
          details {
            size
          }
        }
      }
      downloadImages {
        title
        file {
          contentType
          url
          details {
            size
          }
        }
      }
      stageImage {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
        title
      }
      stageImageMobile {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
        title
      }
      stageImageWithFocalpoint {
        image {
          fluid(maxWidth: 2000, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
          title
        }
        focalPoint
      }
      ogDescription
      relatedArticles {
        ... on ContentfulArticle {
          id
          slug
          title
          updatedAt
          teaserCopy {
            childMarkdownRemark {
              html
            }
          }
          teaserImage {
            fluid(maxWidth: 604, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          teaserImageWithFocalpoint {
            image {
              fluid(maxWidth: 704, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            focalPoint
          }
          topic___category {
            slug
            topic___category___list {
              identifier
            }
          }
        }
        ... on ContentfulPageExternal {
          id
          titleInternal
          url
          teaserTitle
          teaserCopy {
            childMarkdownRemark {
              html
            }
          }
          teaserImage {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
            title
          }
        }
      }
    }
  }
`
